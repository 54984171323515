import appConfig from '@/app.config';
import { createStorageContainer } from '@/connection-assurance/api/createStorageContainer';
import { getSas } from '@/connection-assurance/api/getSas';
import { uploadAdditionalDocuments } from '@/connection-assurance/api/uploadAdditionalDocuments';
import useApi from '@/hooks/useApi';
import { CONNECTION_ASSURANCE_DOCUMENTS } from '@/routes';
import { getAllPlantComponents } from '@/utils/getAllPlantComponents';
import useConnectionAssurance from '@/utils/useConnectionAssurance';
import { getUploadGroups } from '@components/documentUpload/documentUploadForm/getUploadGroups';
import {
    CenteredLoadingSpinner,
    Checkbox,
    ErrorBox,
    Form,
    createProcessGuidanceButtons,
} from '@ten-netzkundenportal/ui-components';
import {
    CombinedUploadState,
    DataLessFileTransfer,
    GroupedDocumentUploadForm,
    useContainerClientWrapper,
} from '@ten-netzkundenportal/ui-document-upload';
import React, { useCallback, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

interface ConnectionAssuranceDocumentsUploadProps {
    connectionAssuranceId: string;
}

const ConnectionAssuranceDocumentsUpload = ({
    connectionAssuranceId,
}: ConnectionAssuranceDocumentsUploadProps): React.ReactElement => {
    const history = useHistory();
    const { connectionAssurance, isLoading } = useConnectionAssurance(connectionAssuranceId);

    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
    const [showUploadError, setShowUploadError] = useState(false);
    const [folderName] = useState(() => new Date().toISOString().replace(/:/g, '-'));
    const [isFileCategoryMissing, setIsFileCategoryMissing] = useState<boolean>(false);
    const [combinedUploadState, setCombinedUploadState] = useState<CombinedUploadState>('initial');

    const getSasApi = useApi(getSas);
    const createStorageContainerApi = useApi(createStorageContainer);
    const createStorageContainerCallback = useCallback(
        () => createStorageContainerApi({ connectionAssuranceId }),
        [connectionAssuranceId, createStorageContainerApi],
    );
    const createSasCallback = useCallback(
        () => getSasApi({ connectionAssuranceId }),
        [getSasApi, connectionAssuranceId],
    );
    const uploadAdditionalDocumentsApi = useApi(uploadAdditionalDocuments);

    const containerClientWrapper = useContainerClientWrapper({
        storageApiUrl: appConfig.services.connectionUserUploadApi,
        containerName: `connection-assurance-${connectionAssuranceId}`,
        createSas: createSasCallback,
        createStorageContainer: createStorageContainerCallback,
        folderName,
    });

    const handleUploadedFiles = useCallback(
        (files: DataLessFileTransfer[], newCombinedUploadState: CombinedUploadState) => {
            setIsFileCategoryMissing(files.some((f) => !f.fileCategory));
            setCombinedUploadState(newCombinedUploadState);
        },
        [],
    );

    if (isLoading) {
        return <CenteredLoadingSpinner />;
    }

    const documentsViewPath = generatePath(CONNECTION_ASSURANCE_DOCUMENTS, {
        connectionAssuranceId,
    });

    const submitForm = async () => {
        try {
            await uploadAdditionalDocumentsApi({
                connectionAssuranceId,
                folderName,
                privacyPolicyAccepted,
            });
            history.push(documentsViewPath);
        } catch (error) {
            console.error('Failed to upload documents', error);
            setShowUploadError(true);
        }
    };

    const { nextButton, prevButton } = createProcessGuidanceButtons({
        loading: combinedUploadState === 'loading',
        isNextValid: privacyPolicyAccepted && combinedUploadState === 'success' && !isFileCategoryMissing,
        onBack: () => history.push(documentsViewPath),
        onNext: submitForm,
        nextLabel: 'Absenden',
        nextTitle: isFileCategoryMissing
            ? 'Bitte wählen Sie für jedes hochgeladene Dokument eine Dokumentenart aus.'
            : 'Absenden',
    });

    return (
        <>
            <Form title="Dokumentenupload" leftButton={prevButton} rightButton={nextButton}>
                <span>
                    Hier können Sie notwendige Dokumente, die Sie bei der Antragsstellung noch nicht zur Hand hatten,
                    hochladen und uns zur Verfügung stellen. Die folgenden Dateitypen sind möglich (jeweils max. 20 MB):
                    Word, PDF, JPG/JPEG, TIFF, PNG, DWG, DXF. Bitte wählen Sie für jedes hochgeladene Dokument eine
                    Dokumentenart aus.
                </span>
                <GroupedDocumentUploadForm
                    containerClientWrapper={containerClientWrapper}
                    uploadGroups={getUploadGroups({
                        plantSize: connectionAssurance.plantSize,
                        plantComponents: getAllPlantComponents(
                            connectionAssurance.plantComponents,
                            connectionAssurance.plantComponentsGenerator,
                            connectionAssurance.plantComponentsStorage,
                        ),
                        numberOfLocation: connectionAssurance.numberOfLocation,
                        showCompensationDocuments: true,
                        showRequiredDocumentsSection: false,
                    })}
                    acceptedFileExtensions={['.doc', '.docx', '.pdf', '.jpg', '.jpeg', '.tiff', '.png', '.dwg', '.dxf']}
                    existingFileCategories={new Set(connectionAssurance.uploadedFileCategories)}
                    maxFileSizeMB={20}
                    onChange={handleUploadedFiles}
                />
                <div className="flex justify-end px-5 gap-x-5 mt-4">
                    <span>
                        Ich erkläre, dass ich die{' '}
                        <a className="text-primary" target="_blank" rel="noopener noreferrer" href="/files/DSI.pdf">
                            Datenschutzinformation
                        </a>{' '}
                        zur Kenntnis genommen habe.
                    </span>
                    <Checkbox
                        data-testid="accept-privacy-policy"
                        name="check-accepted-privacy-policy"
                        onChange={(event) => setPrivacyPolicyAccepted(event.target.checked)}
                    />
                </div>
            </Form>

            {showUploadError && (
                <ErrorBox
                    onClick={() => setShowUploadError(false)}
                    text={[
                        'Beim Hochladen der Dokumente ist leider ein Fehler aufgetreten.',
                        'Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
                    ]}
                />
            )}
        </>
    );
};

export default ConnectionAssuranceDocumentsUpload;
