import getContentPerFileCategoryForCommissioningGenerationPlantDocuments from '@/components/documentUpload/documentUploadForm/commissioning-generation-plant-documents/getContentPerFileCategory';
import getContentPerFileCategoryForConnectionRelevantDocuments from '@/components/documentUpload/documentUploadForm/connection-relevant-document/getContentPerFileCategory';
import {
    CommissioningGenerationPlantDocumentsFileCategory,
    ConnectionRelevantDocumentFileCategory,
} from '@/types/FileUpload';
import { GeneratorType } from '@/types/GeneratorData';
import { PlantSize } from '@/types/PlantSize';
import { EnergyStorageSystemType } from '@/types/StorageData';
import PrintOutLink from '@components/documentUpload/documentUploadForm/PrintOutLink';
import { UploadFileCategory } from '@ten-netzkundenportal/ui-document-upload';
import React, { MutableRefObject } from 'react';

type NetworkCompatibilityAssessmentOption = {
    label: string;
    category: 'connectionRelevantDocuments';
    subCategory: ConnectionRelevantDocumentFileCategory;
};

type CommissioningGenerationPlantOption = {
    label: string;
    category: 'commissioningGenerationPlantDocuments';
    subCategory: CommissioningGenerationPlantDocumentsFileCategory;
};

const getCreateDescriptionFunction = (
    category: 'connectionRelevantDocuments' | 'commissioningGenerationPlantDocuments',
    subCategory: ConnectionRelevantDocumentFileCategory | CommissioningGenerationPlantDocumentsFileCategory,
    plantSize: PlantSize,
) => {
    switch (category) {
        case 'connectionRelevantDocuments':
            return (boundaryDefiningParentRef: MutableRefObject<HTMLElement>) =>
                getContentPerFileCategoryForConnectionRelevantDocuments(
                    subCategory as ConnectionRelevantDocumentFileCategory,
                    boundaryDefiningParentRef,
                );
        case 'commissioningGenerationPlantDocuments':
            if (subCategory === 'commissioningDisplay') {
                return () => (
                    <PrintOutLink link="https://www.thueringer-energienetze.com/Content/Documents/Anschluss/ANS_Anmeldung_Formblatt_Strom.pdf" />
                );
            }

            return (boundaryDefiningParentRef: MutableRefObject<HTMLElement>) =>
                getContentPerFileCategoryForCommissioningGenerationPlantDocuments(
                    subCategory as CommissioningGenerationPlantDocumentsFileCategory,
                    boundaryDefiningParentRef,
                    plantSize,
                );

        default:
            return undefined;
    }
};

export function getCommissioningRequiredDocumentsFileCategories(
    plantSize: PlantSize,
    generatorType: GeneratorType,
    energyStorageSystem: EnergyStorageSystemType,
    existingFileCategories: Set<string>,
) {
    const options: (NetworkCompatibilityAssessmentOption | CommissioningGenerationPlantOption)[] = [
        {
            label: 'Anmeldung Netzanschluss inklusive Inbetriebsetzung',
            category: 'commissioningGenerationPlantDocuments',
            subCategory: 'commissioningDisplay',
        },
        {
            label: 'Übersichtsschaltplan',
            category: 'connectionRelevantDocuments',
            subCategory: 'wiringOverviewDiagram',
        },
        ...(plantSize === 'small'
            ? ([
                  generatorType === 'solarEnergy'
                      ? {
                            label: 'Fotodokumentation Solarmodulfeld',
                            category: 'commissioningGenerationPlantDocuments',
                            subCategory: 'solarModulePhotoDocumentation',
                        }
                      : undefined,
                  generatorType === 'solarEnergy' || energyStorageSystem === 'dcSystem'
                      ? {
                            label: 'Fotodokumentation Wechselrichtertypenschild',
                            category: 'commissioningGenerationPlantDocuments',
                            subCategory: 'inverterTypePlatePhotoDocumentation',
                        }
                      : undefined,
                  energyStorageSystem === 'acSystem'
                      ? {
                            label: 'Fotodokumentation Energiespeichersystem',
                            category: 'commissioningGenerationPlantDocuments',
                            subCategory: 'energyStorageSystemPhotoDocumentation',
                        }
                      : undefined,
                  generatorType && generatorType !== 'solarEnergy'
                      ? {
                            label: 'Fotodokumentation Generatortypenschild',
                            category: 'commissioningGenerationPlantDocuments',
                            subCategory: 'generatorTypePlatePhotoDocumentation',
                        }
                      : undefined,
              ] satisfies CommissioningGenerationPlantOption[])
            : []),
    ];

    return options
        .filter((option) => !!option)
        .map(
            (option) =>
                ({
                    label: option.label,
                    value: option.subCategory,
                    createDescription: getCreateDescriptionFunction(option.category, option.subCategory, plantSize),
                    required: !existingFileCategories.has(option.subCategory),
                    tags: {
                        category: option.category,
                        subCategory: option.subCategory,
                    },
                }) satisfies UploadFileCategory,
        );
}
