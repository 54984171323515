import { REQUIRED_ERROR_MESSAGE } from '@/utils/textConstants';
import { FormItem, StyledLink, TextField } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Context } from '../wizard/context';

type MasterDataRegisterNumberFieldProps = {
    plantType: 'generation' | 'storage';
};

export const MasterDataRegisterNumberField = ({ plantType }: MasterDataRegisterNumberFieldProps) => {
    const prefix = plantType === 'generation' ? 'SEE' : 'SSE';
    const fieldName =
        plantType === 'generation' ? 'generatorData.maStRGenerationPlant' : 'storageData.maStRGeneratorStorage';

    const {
        register,
        formState: { errors },
    } = useFormContext<Context>();

    return (
        <FormItem label="Bitte geben Sie die Marktstammdatenregister-Nummer der Anlage an:" className="max-w-[32rem]">
            <div className="mb-4">
                <TextField
                    label={`Marktstammdatenregister-Nummer ${plantType === 'generation' ? 'der Erzeugungsanlage' : 'des Energiespeichersystems'}`}
                    additionalClassname="pl-9"
                    prefix={prefix}
                    id={fieldName}
                    data-testid={fieldName}
                    displayErrorMessage={
                        plantType === 'generation'
                            ? errors.generatorData?.maStRGenerationPlant?.message
                            : errors.storageData?.maStRGeneratorStorage?.message
                    }
                    {...register(fieldName, {
                        required: REQUIRED_ERROR_MESSAGE,
                        pattern: {
                            value: /^\d{12}$/,
                            message: `Bitte geben Sie die 15-stellige ${prefix}-Nummer an.`,
                        },
                    })}
                />
            </div>
            <p className="text-small">
                <b>Hinweis:</b> Bitte melden Sie die Inbetriebsetzung im{' '}
                <StyledLink target="_blank" href="https://www.marktstammdatenregister.de/MaStR">
                    Marktstammdatenregister
                </StyledLink>{' '}
                an. Die Registrierung der Anlage ist spätestens einen Monat nach der Inbetriebsetzung abzuschließen.
            </p>
        </FormItem>
    );
};
