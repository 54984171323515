import { msalConfig } from '@/authentication.config';
import { InitializingMsalProvider } from '@ten-netzkundenportal/ui-auth-utils';
import { ErrorBoundary } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';

import Root from './root.component';

const lifecycles = singleSpaReact({
    React,
    ReactDOMClient,
    rootComponent: () => (
        <InitializingMsalProvider msalConfig={msalConfig}>
            <Root />
        </InitializingMsalProvider>
    ),
    errorBoundary: ErrorBoundary,
});

export const { bootstrap, mount, unmount } = lifecycles;
