import appConfig from '@/app.config';
import { createStorageContainer } from '@/connection-assurance/api/createStorageContainer';
import { getSas } from '@/connection-assurance/api/getSas';
import useApi from '@/hooks/useApi';
import { CONNECTION_ASSURANCE_DOCUMENTS, ROOT_CONTRACT } from '@/routes';
import { RouteProperties } from '@/types/RouteProperties';
import { Form, StyledLink, createProcessGuidanceButtons } from '@ten-netzkundenportal/ui-components';
import { FileTransfer } from '@ten-netzkundenportal/ui-components/build/input/uploadArea/types';
import {
    CombinedUploadState,
    DataLessFileTransfer,
    GroupedDocumentUploadForm,
    UploadGroup,
    useContainerClientWrapper,
} from '@ten-netzkundenportal/ui-document-upload';
import React, { useCallback, useState } from 'react';
import { generatePath } from 'react-router-dom';

import { CommissioningProcessProps } from '../CommissioningProcessProps';
import { getCommissioningRequiredDocumentsFileCategories } from './required-documents/getFileCategory';

export const DocumentUpload = ({
    onSubmit,
    goBack,
    title,
    context,
    updateContext,
    connectionAssurance,
}: RouteProperties & CommissioningProcessProps) => {
    if (!context.uploadFolderName) {
        updateContext({ uploadFolderName: new Date().toISOString().replace(/:/g, '-') });
    }

    const [existingFiles] = React.useState(context.uploadedDocuments as FileTransfer[]);
    const [isFileCategoryMissing, setIsFileCategoryMissing] = useState<boolean>(false);
    const [isRequiredFileMissing, setIsRequiredFileMissing] = useState<boolean>(false);
    const [combinedUploadState, setCombinedUploadState] = useState<CombinedUploadState>('initial');

    const existingFileCategories = new Set(connectionAssurance.uploadedFileCategories);
    existingFileCategories.delete('commissioningDisplay');

    const getSasApi = useApi(getSas);
    const createStorageContainerApi = useApi(createStorageContainer);
    const createStorageContainerCallback = useCallback(
        () =>
            createStorageContainerApi({
                connectionAssuranceId: connectionAssurance.id,
            }),
        [connectionAssurance.id, createStorageContainerApi],
    );
    const createSasCallback = useCallback(
        () =>
            getSasApi({
                connectionAssuranceId: connectionAssurance.id,
            }),
        [getSasApi, connectionAssurance.id],
    );

    const containerClientWrapper = useContainerClientWrapper({
        storageApiUrl: appConfig.services.connectionUserUploadApi,
        containerName: `connection-assurance-${connectionAssurance.id}`,
        createSas: createSasCallback,
        createStorageContainer: createStorageContainerCallback,
        folderName: context.uploadFolderName,
    });

    const handleUploadedFiles = useCallback(
        (
            files: DataLessFileTransfer[],
            newCombinedUploadState: CombinedUploadState,
            newIsRequiredFileMissing: boolean,
        ) => {
            setIsFileCategoryMissing(files.some((f) => !f.fileCategory));
            setIsRequiredFileMissing(newIsRequiredFileMissing);
            setCombinedUploadState(newCombinedUploadState);

            updateContext({ uploadedDocuments: files });
        },
        [updateContext],
    );

    const getNextTitle = () => {
        if (isFileCategoryMissing) {
            return 'Bitte wählen Sie für jedes hochgeladene Dokument eine Dokumentenart aus.';
        }
        if (isRequiredFileMissing) {
            return 'Bitte laden Sie alle verpflichtenden Unterlagen hoch.';
        }
        return 'Weiter';
    };

    const { nextButton, prevButton } = createProcessGuidanceButtons({
        isNextValid:
            (combinedUploadState === 'initial' && !isRequiredFileMissing) ||
            (combinedUploadState === 'success' && !isFileCategoryMissing && !isRequiredFileMissing),
        onBack: goBack,
        onNext: onSubmit,
        nextTitle: getNextTitle(),
    });

    const documentsViewPath = generatePath(CONNECTION_ASSURANCE_DOCUMENTS, {
        connectionAssuranceId: connectionAssurance.id,
    });

    return (
        <Form title={title} rightButton={nextButton} leftButton={prevButton} handleSubmit={onSubmit}>
            <span>
                Bitte laden Sie hier die verpflichtenden Unterlagen hoch, um die Anlage in Betrieb setzen zu können. Die
                folgenden Dateitypen sind möglich (jeweils max. 20 MB): Word, PDF, JPG/JPEG, TIFF, PNG, DWG, DXF. Bitte
                wählen Sie für jedes hochgeladene Dokument eine Dokumentenart aus.
            </span>

            <GroupedDocumentUploadForm
                containerClientWrapper={containerClientWrapper}
                uploadGroups={[
                    {
                        id: 'commissioning-required-documents',
                        heading: 'Verpflichtende Unterlagen',
                        fileCategories: getCommissioningRequiredDocumentsFileCategories(
                            connectionAssurance.plantSize,
                            connectionAssurance.generatorData?.generalGeneratorType.generatorType,
                            connectionAssurance.storageData?.energyStorageSystem,
                            existingFileCategories,
                        ),
                    } satisfies UploadGroup,
                ]}
                acceptedFileExtensions={['.doc', '.docx', '.pdf', '.jpg', '.jpeg', '.tiff', '.png', '.dwg', '.dxf']}
                maxFileSizeMB={20}
                onChange={handleUploadedFiles}
                existingFileCategories={existingFileCategories}
                existingFiles={existingFiles}
                useAccordions={false}
                enumerateGroups={false}
            />
            <span>
                Eine Übersicht über alle Dokumente zu diesem Vorgang finden Sie in der{' '}
                <StyledLink href={`${ROOT_CONTRACT}${documentsViewPath}`} target="_blank">
                    Dokumentenverwaltung
                </StyledLink>
                .
            </span>
        </Form>
    );
};
