import { ColumnItem, ColumnSection, MultiColumn, SubColumn } from '@ten-netzkundenportal/ui-components';
import React from 'react';

import { GeneratorData } from '../../types/GeneratorData';
import { PlantComponent, SmallestPlantComponentProperties } from '../../types/PlantComponent';
import { PlantData, PlantOperator } from '../../types/PlantData';
import { StorageData } from '../../types/StorageData';
import { formatNumber } from '../../utils/formatNumber';
import {
    formatGermanAddress,
    formatInternationalAddress,
    formatPlantOperatorCompanyName,
    formatPlantOperatorPersonName,
    formatTelephoneNumber,
} from '../../utils/formatting';
import { calculateSumOfActivePowersInKW, calculateSumOfApparentPowersInKVA } from '../../utils/plantComponents';

interface GenerationColumnProps {
    generatorData?: GeneratorData;
    storageData?: StorageData;
    plantComponents: PlantComponent[];
    plantData: PlantData;
    smallestPlantComponentProperties?: SmallestPlantComponentProperties;
}

const getGenerationTypeText = (generatorData: GeneratorData) => {
    const energyTypeNames = { solarEnergy: 'Solarenergie', windEnergy: 'Windenergie' };
    const otherEnergyTypeNames = {
        biogas: 'Biogas (EEG)',
        naturalGas: 'Erdgas (KWKG)',
        geothermal: 'Geothermie (EEG)',
        oil: 'Öl (KWKG)',
        water: 'Wasser (EEG)',
    };
    return (
        energyTypeNames[generatorData.generalGeneratorType?.generatorType] ??
        otherEnergyTypeNames[generatorData.generalGeneratorType?.otherEnergyType] ??
        ''
    );
};

const getUsageOfElectricityTypeText = (generatorType: GeneratorData) => {
    if (generatorType.surplusPowerFeedIn) {
        return 'ja (Überschussstromeinspeisung)';
    }
    return 'nein (Vollstromeinspeisung)';
};

const isShowEnergyStorageMaStR = (storageData?: StorageData) =>
    storageData?.energyStorageSystem === 'acSystem' && storageData.maStRGeneratorStorage;

const getStorageTypeText = (storageData: StorageData) => {
    if (storageData.energyStorageSystem === 'acSystem') {
        return 'AC';
    }
    return 'DC';
};

const getFeedInStoredEnergyText = (storageData: StorageData) =>
    storageData.feedStoredEnergyIntoPublicNetwork ? 'ja' : 'nein';

const getLoadStoredEnergyText = (storageData: StorageData) =>
    storageData.loadStoredEnergyWithPublicNetwork ? 'ja' : 'nein';

const getSumOfApparentPowerText = (plantComponents: PlantComponent[]) =>
    `${formatNumber(calculateSumOfApparentPowersInKVA(plantComponents))} kVA`;

const getSumOfActivePowerText = (plantComponents: PlantComponent[]) =>
    `${formatNumber(calculateSumOfActivePowersInKW(plantComponents))} kW`;

const getSumOfSmallestPlantComponents = (smallestPlantComponentProperties: SmallestPlantComponentProperties) => {
    const sumInkW =
        (smallestPlantComponentProperties.activeGeneratorPowerInW ?? 0) +
        (smallestPlantComponentProperties.activeEnergyStoragePowerInW ?? 0);

    return `${formatNumber(sumInkW / 1000, 1, 3)} kW`;
};

const isShowSolarEnergyPower = (generator?: GeneratorData) =>
    generator?.generalGeneratorType?.generatorType === 'solarEnergy';
const getSolarEnergyPowerText = (solarModuleCapacityInkWp?: number) =>
    `${formatNumber(solarModuleCapacityInkWp || 0, 1, 3)} kWp`;

const getStorageCapacityText = (energyStorageCapacityInkWh: number) =>
    `${formatNumber(energyStorageCapacityInkWh || 0, 1, 3)} kWh`;

const getMaStROfGenerationPlantText = (generatorData: GeneratorData) => `SEE ${generatorData.maStRGenerationPlant}`;

const getMaStROfGenerationStorageText = (storageData: StorageData) => `SSE ${storageData.maStRGeneratorStorage}`;

const getPlantOperatorName = (plantOperator: PlantOperator) => {
    if ('person' in plantOperator) {
        return formatPlantOperatorPersonName(plantOperator.person);
    }
    if ('company' in plantOperator) {
        return formatPlantOperatorCompanyName(plantOperator.company);
    }
    return '';
};

const getPlantOperatorAddressText = (plantOperator: PlantOperator) => ({
    label: 'Adresse',
    text: [
        getPlantOperatorName(plantOperator),
        ...(plantOperator.addressType === 'german'
            ? formatGermanAddress(plantOperator.address)
            : formatInternationalAddress(plantOperator.intAddress)),
    ],
});

const getPlantOperatorTelephoneText = (plantOperator: PlantOperator) =>
    formatTelephoneNumber(plantOperator.contact.telephone);

const GenerationColumn = ({
    generatorData,
    storageData,
    plantComponents,
    plantData,
    smallestPlantComponentProperties,
}: GenerationColumnProps): React.ReactElement => (
    <div className="col-span-2">
        <MultiColumn title="Erzeugungsanlage">
            <SubColumn>
                <ColumnSection title="Erzeugungsanlage">
                    {generatorData ? (
                        <>
                            <ColumnItem label="Energieart" text={getGenerationTypeText(generatorData)} />
                            <ColumnItem
                                label="Nutzung des erzeugten Stroms"
                                text={getUsageOfElectricityTypeText(generatorData)}
                            />
                        </>
                    ) : (
                        <p className="italic">keine Angabe</p>
                    )}
                </ColumnSection>

                <ColumnSection title="Speicherung">
                    {storageData ? (
                        <>
                            <ColumnItem label="Art des Energiespeichers" text={getStorageTypeText(storageData)} />
                            <ColumnItem
                                label="Einspeisung in das öffentliche Netz"
                                text={getFeedInStoredEnergyText(storageData)}
                            />
                            <ColumnItem
                                label="Aufladung aus dem öffentlichen Netz"
                                text={getLoadStoredEnergyText(storageData)}
                            />
                        </>
                    ) : (
                        <p className="italic">keine Angabe</p>
                    )}
                </ColumnSection>
            </SubColumn>

            <SubColumn>
                <ColumnSection title="Anlagenkomponenten">
                    {smallestPlantComponentProperties ? (
                        <ColumnItem
                            label="Summe Wechselrichterleistung"
                            text={getSumOfSmallestPlantComponents(smallestPlantComponentProperties)}
                        />
                    ) : (
                        <>
                            <ColumnItem
                                label="Summe Scheinleistung"
                                text={getSumOfApparentPowerText(plantComponents)}
                            />
                            <ColumnItem label="Summe Wirkleistung" text={getSumOfActivePowerText(plantComponents)} />
                        </>
                    )}
                    {isShowSolarEnergyPower(generatorData) && (
                        <ColumnItem
                            label="Solarmodulleistung"
                            text={getSolarEnergyPowerText(generatorData.solarModuleCapacityInkWp)}
                        />
                    )}
                    {storageData && (
                        <ColumnItem
                            label="Speicherkapazität"
                            text={getStorageCapacityText(storageData.energyStorageCapacityInkWh)}
                        />
                    )}
                    {generatorData && generatorData.maStRGenerationPlant && (
                        <ColumnItem
                            label="Markstammdatenregister-Nr. Erzeugungsanlage"
                            text={getMaStROfGenerationPlantText(generatorData)}
                        />
                    )}
                    {isShowEnergyStorageMaStR(storageData) && (
                        <ColumnItem
                            label="Markstammdatenregister-Nr. Energiespeicher"
                            text={getMaStROfGenerationStorageText(storageData)}
                        />
                    )}
                </ColumnSection>
                <ColumnSection title="Anlagenbetreiber">
                    <ColumnItem {...getPlantOperatorAddressText(plantData.plantOperator)} />
                    <ColumnItem label="Telefonnummer" text={getPlantOperatorTelephoneText(plantData.plantOperator)} />
                </ColumnSection>
            </SubColumn>
        </MultiColumn>
    </div>
);

export default GenerationColumn;
