import { patchConnectionAssuranceCommissioning } from '@/connection-assurance/commissioning/api/patchConnectionAssuranceCommissioning';
import { AcknowledgementFormItem } from '@/connection-assurance/commissioning/completion-confirmation/AcknowledgementFormItem';
import { CommissioningDateFormItem } from '@/connection-assurance/commissioning/completion-confirmation/CommissioningDateFormItem';
import { CommissioningData } from '@/connection-assurance/commissioning/types/CommissioningData';
import { transformContext } from '@/connection-assurance/commissioning/utils/transformContext';
import { useContextUpdate } from '@/connection-assurance/commissioning/wizard/hooks';
import useApi from '@/hooks/useApi';
import { RouteProperties } from '@/types/RouteProperties';
import { calculateSumOfActivePowersInKW } from '@/utils/plantComponents';
import { ErrorBox, Form, createProcessGuidanceButtons } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { CommissioningProcessProps } from '../CommissioningProcessProps';

const determineReactivePowerProvisionGenerationPlantText = (isSolarEnergy: boolean, isDCStorage: boolean) => {
    if (!isSolarEnergy) {
        return (
            <>
                ¹Synchrongeneratoren, Stirlinggeneratoren, Brennstoffzellen ≤&nbsp;4,6&nbsp;kVA keine Vorgabe zum
                Blindleistungsverhalten, möglichst cos&nbsp;φ&nbsp;=&nbsp;0,95 untererregt.
            </>
        );
    }
    if (isSolarEnergy && isDCStorage) {
        return (
            <>
                Wenn Erzeugungseinheit und Speicher DC-gekoppelt sind, gelten bei Energielieferung des gemeinsamen
                Umrichters die Anforderungen von Erzeugungsanlagen.
            </>
        );
    }
    return null;
};

export const CompletionConfirmation = ({
    goBack,
    onSubmit,
    context,
    updateContext,
    title,
    connectionAssurance,
}: RouteProperties & CommissioningProcessProps) => {
    const patchConnectionAssuranceCommissioningApi = useApi(patchConnectionAssuranceCommissioning);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showSubmitError, setShowSubmitError] = useState<boolean>(false);

    const {
        formState: { errors, isValid },
        register,
        watch,
    } = useForm<CommissioningData>({
        mode: 'onBlur',
        defaultValues: {
            technicalCommissioningDateOfGenerator: context.technicalCommissioningDateOfGenerator,
            technicalCommissioningDateOfStorage: context.technicalCommissioningDateOfStorage,
            acknowledgements: context.acknowledgements,
        },
    });

    useContextUpdate(watch, updateContext, 'COMPLETION_CONFIRMATION');

    const allPlantComponents = [...context.plantComponentsGenerator, ...context.plantComponentsStorage];

    const activePowerAbove25kW = calculateSumOfActivePowersInKW(allPlantComponents) >= 25;
    const hasGenerationPlant = context.plantComponentsGenerator?.length > 0;
    const hasEnergyStorage = context.plantComponentsStorage?.length > 0;
    const hasSinglePhaseInverter = allPlantComponents.some(
        (plantComponent) => plantComponent.inverterPhaseType === 'single-phase',
    );
    const isSolarEnergy =
        hasGenerationPlant && connectionAssurance.generatorData?.generalGeneratorType.generatorType === 'solarEnergy';
    const isDCStorage = connectionAssurance.storageData?.energyStorageSystem === 'dcSystem';

    const handleSubmit = async () => {
        setIsSubmitting(true);
        setShowSubmitError(false);

        try {
            await patchConnectionAssuranceCommissioningApi({
                connectionAssuranceId: connectionAssurance.id,
                commissioningData: transformContext(context),
            });

            onSubmit();
        } catch (e) {
            console.error('Submit commissioning data failed', e);
            setShowSubmitError(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    const { nextButton, prevButton } = createProcessGuidanceButtons({
        isNextValid: isValid,
        isPrevValid: !isSubmitting,
        onBack: goBack,
        onNext: handleSubmit,
        nextLabel: 'Abschicken',
        loading: isSubmitting,
    });

    return (
        <Form title={title} rightButton={nextButton} leftButton={prevButton} handleSubmit={handleSubmit}>
            <div className="grid grid-cols-4 gap-4">
                <AcknowledgementFormItem
                    id="integratedGridAndPlantProtection"
                    title="Integrierter NA-Schutz"
                    label={
                        <>
                            Der integrierte NA-Schutz erfüllt folgende Eigenschaft:
                            <br />
                            Eingestellter Wert Spannungssteigerungsschutz U&nbsp;&gt; nach VDE-AR-N&nbsp;4105, Tabelle
                            2.
                        </>
                    }
                    register={register}
                />
                {activePowerAbove25kW && (
                    <AcknowledgementFormItem
                        id="intelligentMeteringSystemInstallationCommissioned"
                        title="Technische Einrichtung zur Reduzierung der Erzeugungsleistung (gemäß EEG ab 25 kW/kWp)"
                        label="Der Verbau eines intelligenten Messsystems (iMSys) wurde beauftragt."
                        register={register}
                    />
                )}
                {hasSinglePhaseInverter && (
                    <>
                        <AcknowledgementFormItem
                            id="complianceWithSymmetryConditionBySplitting"
                            title="Einhaltung der Symmetriebedingung"
                            label={
                                <>
                                    Die Symmetriebedingung wird durch eine Aufteilung der einphasig angeschlossenen
                                    Erzeugungseinheiten je Außenleiter eingehalten, so dass bei
                                    Erzeugungsanlagen/Energiespeichern die Unsymmetrie auf 4,6&nbsp;kVA je Außenleiter
                                    begrenzt ist.
                                </>
                            }
                            register={register}
                        />
                        <AcknowledgementFormItem
                            id="complianceWithSymmetryConditionBySymmetryDevice"
                            label={
                                <>
                                    Die Symmetriebedingung wird durch eine Symmetrieeinrichtung, die den Unsymmetriewert
                                    auf 4,6&nbsp;kVA je Außenleiter begrenzt, eingehalten.
                                </>
                            }
                            register={register}
                        />
                    </>
                )}
                {hasGenerationPlant && (
                    <AcknowledgementFormItem
                        id="reactivePowerProvisionGenerationPlant"
                        title="Blindleistungsbereitstellung"
                        label={
                            <>
                                Die Erzeugungsanlagen{!isSolarEnergy ? '¹' : ''} werden mit der Kennlinie
                                cos&nbsp;φ&nbsp;(P) nach Bild&nbsp;9 der VDE-AR-N&nbsp;4105 betrieben.
                                <br />
                                <br />
                                {determineReactivePowerProvisionGenerationPlantText(isSolarEnergy, isDCStorage)}
                            </>
                        }
                        register={register}
                    />
                )}
                {hasEnergyStorage && (
                    <AcknowledgementFormItem
                        id="reactivePowerProvisionEnergyStorage"
                        title={hasGenerationPlant ? undefined : 'Blindleistungsbereitstellung'}
                        label={
                            <>
                                Das Energiespeichersystem (ohne Lieferung in das öffentliche Netz und ohne
                                Leistungsbezug aus dem öffentlichen Netz) wird mit einem cos&nbsp;φ&nbsp;=&nbsp;1
                                betrieben.
                            </>
                        }
                        register={register}
                    />
                )}

                <AcknowledgementFormItem
                    id="installationOfBidirectionalMeter"
                    title="Zweienergierichtungszähler"
                    label="Ein Zweienergierichtungszähler ist bereits verbaut oder der Einbau ist beauftragt."
                    register={register}
                />

                {hasGenerationPlant && (
                    <CommissioningDateFormItem
                        title="Datum der Inbetriebsetzung"
                        label="Bitte geben Sie das Datum der Inbetriebsetzung der Erzeugungsanlage an."
                        id="technicalCommissioningDateOfGenerator"
                        register={register}
                        errors={errors}
                    />
                )}

                {hasEnergyStorage && (
                    <CommissioningDateFormItem
                        title={hasGenerationPlant ? undefined : 'Datum der Inbetriebsetzung'}
                        label="Bitte geben Sie das Datum der Inbetriebsetzung des Energiespeichers an."
                        id="technicalCommissioningDateOfStorage"
                        register={register}
                        errors={errors}
                    />
                )}

                <p className="col-span-4 font-bold mt-5">Erklärung zur Inbetriebsetzung</p>
                <div className="col-span-3">
                    Die Erzeugungsanlage/das Energiespeichersystem ist/sind nach VDE-AR-N&nbsp;4105, VDE-AR-N&nbsp;4100
                    und den technischen Anschlussbestimmungen (TAB) des Netzbetreibers errichtet worden. Als
                    Installationsbetrieb, habe ich den Anlagenbetreiber unterwiesen und eine vollständige Dokumentation
                    inkl. Schaltplan nach den jeweils gültigen VDE-Bestimmungen übergeben.
                </div>

                <AcknowledgementFormItem
                    id="declarationOfCommissioning"
                    label="Ich bestätige die Erklärung zur Inbetriebsetzung."
                    register={register}
                />
            </div>

            {showSubmitError && <ErrorBox onClick={() => setShowSubmitError(false)} />}
        </Form>
    );
};
